<template>
  <div class="cbox" style="height: 100%;">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->

        <tchtree v-model="class_id" @change="getData"></tchtree>
        <span style="margin-left:20px">选择月：</span>
        <!--        <el-date-picker  @change="getData" v-model="sign_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">-->
        <!--        </el-date-picker>-->

        <el-date-picker @change="changeDate" size="small" v-model="year_week" type="month" value-format="yyyy-MM"
          placeholder="选择月">
        </el-date-picker>



      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>数据统计</el-breadcrumb-item>
          <el-breadcrumb-item>巡访统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent" style="width: 100%;height: calc( 100% - 80px );">
      <div id="chartBox" :style="sessionArr.length < 2 ? 'height:70%;' : 'height:500px;'"></div>
      <div id="chartBox_2" v-show="!isClassLeader" style="width: 100%;height: 500px;"></div>
      <div id="chartBox1" style="width: 100%;margin-top: 50px;">
        <el-table v-tableFit :data="tableData" style="width: 100%;margin-bottom: 20px;" height="74vh" border stripe>
          <el-table-column prop="loginname" label="工号" width="" align="center" />

          <el-table-column prop="username" label="姓名" width="200" />

          <el-table-column prop="count_number" label="总次数" width="" align="center"></el-table-column>

          <el-table-column prop="count_people" label="总人次" width="" align="center"></el-table-column>
          <el-table-column prop="visit_dh" label="电话巡访" width="" align="center"></el-table-column>
          <el-table-column prop="visit_sp" label="视频巡访" width="" align="center"></el-table-column>
          <el-table-column prop="visit_sd" label="实地巡访" width="" align="center"></el-table-column>

          <!--          <el-table-column v-for="(item,index) in sxqxArr" :prop="'count_'+item.param_value" :label="item.param_desc" width="" align="center">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span>{{scope.row['count_'+item.param_value]}}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
      </div>

    </div>



  </div>
</template>

<script>
import util from "../../../../utils/util.js"
import tchtree from '../../../com/tchTree.vue'
export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: "",
      sign_date: util.formatDate(new Date()),
      dataList: [],
      dataList1: [],
      year_week: '',
      report_year: '',
      week: '',
      tableData: [],
      isClassLeader: false,
      sessionArr: [],

    }
  },
  mounted() {
    this.getConfig()
    this.getMyClasses1()

    this.getMyClasses()
  },
  methods: {
    getMyClasses1() {

      this.$http.post("/api/get_teacher_info").then(res => {
        let bzr = []
        if (res.data.class_names && res.data.class_names.length > 0) {
          for (let item of res.data.class_names) {
            if (item.type == "班主任") {
              this.isClassLeader = true
            }
          }
        }


      })
    },
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        //this.class_id = this.myClasses[0].id
        this.getNowDate()
      })
    },
    //获取届次518、519
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: 'tongji_jieci' }).then(res => {
        if (res.data.data && res.data.data[0]) {
          if (res.data.data[0].kvalue && res.data.data[0].kvalue.indexOf(',')) {
            this.sessionArr = res.data.data[0].kvalue.split(',')
          } else {
            this.sessionArr = [res.data.data[0].kvalue]
          }
        }

      })
    },
    getNowDate() {
      this.$http.post("/api/find_date").then(d => {
        this.report_year = d.data.year
        this.week = d.data.week
        this.year_week = util.formatDate()
        this.getData()
        this.getData1()
      })
    },
    changeDate(e) {
      let str = this.$moment(e).utcOffset(480).format("yyyy-WW")

      let arr = str.split('-')
      this.report_year = arr[0]
      this.week = arr[1]
      this.getData()
      this.getData1()
    },
    getData() {
      this.$http.post("/api/stu_visit_statistics", { visit_year: this.year_week.split('-')[0], visit_month: this.year_week.split('-')[1], class_id: this.class_id }).then(res => {
        res.data && res.data.map(a => {
          if (a.xf_rate) {
            a.xf_rate = parseFloat(a.xf_rate) * 100
            a.username = a.NAME + a.username
          }
        })
        this.dataList = res.data
        if (this.sessionArr && this.sessionArr.length > 1) {
          this.initChart()
          this.initChart_2()
        } else {
          this.initChart()
        }
      })
    },
    getData1() {
      this.$http.post("/api/vist_statistics", { visit_year: this.year_week.split('-')[0], visit_month: this.year_week.split('-')[1], class_id: this.class_id }).then(res => {
        this.tableData = res.data
      })
    },
    initChart() {
      let dataList = [];//过滤519的
      if (this.sessionArr && this.sessionArr.length > 0 && this.sessionArr[0]) {
        for (let item of this.dataList) {
          if (item.username.indexOf(this.sessionArr[0]) >= 0) {
            dataList.push(item)
          }
        }
      } else {
        dataList = this.dataList
      }
      let dataAxis = this._lo.map(dataList, 'username')
      let data = this._lo.map(dataList, 'xf_rate')


      let option = {
        title: {
          text: '巡访率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            ////console.log(params);
            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },

        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10,

        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
                            color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                              color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) { //标签内容
              //   return params.value + '%'
              // },
            },

          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)

    },
    initChart_2() {
      let dataList = [];//过滤518的
      if (this.sessionArr && this.sessionArr.length > 1 && this.sessionArr[1]) {
        for (let item of this.dataList) {
          if (item.username.indexOf(this.sessionArr[1]) >= 0) {
            dataList.push(item)
          }
        }
      } else {
        dataList = this.dataList
      }
      let dataAxis = this._lo.map(dataList, 'username')
      let data = this._lo.map(dataList, 'xf_rate')


      let option = {
        title: {
          text: '巡访率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            ////console.log(params);
            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },

        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10,

        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              formatter: function (params) { //标签内容
                return params.value + '%'
              },
            },

          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox_2'));
      myChart.setOption(option)

    },
  },

};
</script>

